















































































































































































































































































































































































































import { defineComponent } from "@vue/composition-api";
import { mapStores } from "pinia";
import { DEFAULT_TRACKING } from "@/lib/constants/misc";
import { useGebruikerStore } from "@/pinia/gebruiker";
import { usePopupStore } from "@/pinia/popup";
import type { Doel, DoelDetail } from "@/services/types/coach";
import { getDayMonthYear } from "@/utils/date";

const doelDetailOptions: Record<
	DoelDetail["slug"],
	{
		slug: string;
		from: number;
		to: number;
		n: number;
		step?: number;
		textAfter: string;
	}
> = {
	gewicht: {
		slug: "gewicht",
		from: 49,
		to: 121,
		n: 70,
		textAfter: "kg",
	},
	vetpercentage: {
		slug: "vetpercentage",
		from: 0,
		to: 50,
		step: 0.1,
		n: 500,
		textAfter: "%",
	},
	bmi: {
		slug: "bmi",
		from: 0,
		to: 100,
		n: 100,
		textAfter: "",
	},
};

type Data = {
	loading: boolean;
	editing: boolean;
	selectedDoelDetail: DoelDetail["slug"] | undefined;
	doelDetailOptions: typeof doelDetailOptions;
	formDoel: Doel;
};

export default defineComponent({
	props: {},
	data(): Data {
		return {
			editing: false,
			loading: true,
			selectedDoelDetail: undefined,
			doelDetailOptions,
			formDoel: {
				tracking: false,
				changed: null,
				fitness: 1,
				activity: "passief",
				goalText: "",
				doel: {
					slug: undefined,
					amount: undefined,
				} as unknown as DoelDetail,
				achieve: {
					cardio: false,
					kracht: false,
					groepslessen: false,
					afvallenInAchtWeken: false,
					sportvasten: false,
					personaltrainer: false,
					spierballenInAchtWeken: false,
				},
				helpAchieve: "",
				timeAchieve: "",
				frequency: 0,
				duration: undefined as unknown as number,
			},
		};
	},
	computed: {
		...mapStores(useGebruikerStore, usePopupStore),
		isTracking(): boolean {
			return this.gebruikerStore?.tracking?.target ?? DEFAULT_TRACKING;
		},
	},
	watch: {
		$route: "check",
		"gebruikerStore.doel"(newValue: Doel) {
			if (!newValue || !newValue.doel || !newValue.achieve) return;

			this.formDoel = {
				...newValue,
				fitness: newValue.fitness === null || newValue.fitness == undefined ? 1 : newValue.fitness,
			};

			if (newValue.doel?.slug) this.selectedDoelDetail = newValue.doel.slug;
		},
	},
	async created() {
		await this.check();

		// @ts-expect-error Required to have the slider correctly render at first
		this.$nextTick(() => this.$refs.slider.refresh());
	},
	async destroyed() {
		this.resetForm();
		this.editing = false;
	},
	methods: {
		async check() {
			this.loading = true;

			const { id } = this.$route.params;

			const numberId = Number(id);

			if (isNaN(numberId)) return;

			const currentId = this.gebruikerStore.id;

			if (currentId !== numberId) this.gebruikerStore.set(numberId);

			await this.getRequiredInfo();
			await this.gebruikerStore.getTracking();

			this.loading = false;
		},
		async getRequiredInfo() {
			await this.gebruikerStore.getDoel();
		},
		async save() {
			const valid = await this.$validator.validateAll();

			if (valid) {
				this.popupStore.open({
					title: "Let op",
					body: "Weet je zeker dat je het doel wilt opslaan? Als er bestaande data is zal deze overschreven worden.",
					buttons: {
						cancel: "Annuleren",
						confirm: "Ik weet het zeker",
					},
					callback: () => this.saveGoal(),
				});
			} else {
				this.popupStore.showError(
					"Niet alle velden zijn ingevuld. Let op de waarschuwingen onder de velden.",
				);
			}
		},
		async saveGoal() {
			try {
				await this.gebruikerStore.saveDoel({
					...this.formDoel,
					changed: getDayMonthYear(new Date(), "short"),
					achieve: {
						...this.formDoel.achieve,
						cardio: this.formDoel.achieve.cardio ?? false,
						kracht: this.formDoel.achieve.kracht ?? false,
						groepslessen: this.formDoel.achieve.groepslessen ?? false,
						afvallenInAchtWeken: this.formDoel.achieve.afvallenInAchtWeken ?? false,
						sportvasten: this.formDoel.achieve.sportvasten ?? false,
						personaltrainer: this.formDoel.achieve.personaltrainer ?? false,
						spierballenInAchtWeken: this.formDoel.achieve.spierballenInAchtWeken ?? false,
					},
				} as Doel);

				this.popupStore.open({
					title: "Gelukt!",
					body: "Het doel is succesvol opgeslagen.",
					buttons: {
						confirm: "Sluiten",
					},
					callback: () => this.toggleEdit(false),
				});
			} catch (error) {
				this.popupStore.showError(
					`Er is iets misgegaan bij het opslaan van het doel: ${error}.<br/>Probeer het later nog eens.`,
				);
			}
		},
		toggleEdit(value?: boolean) {
			this.editing = value ? value : !this.editing;
		},
		isActive(slug: string) {
			return this.formDoel.doel?.slug === slug;
		},
		updateSlugSelection(slug: DoelDetail["slug"] | undefined) {
			this.$nextTick(() => {
				this.formDoel.doel.amount = undefined as unknown as number;
			});
			this.formDoel.doel.slug = slug as unknown as DoelDetail["slug"];
			this.selectedDoelDetail = slug;
		},
		changeAmount(value: number) {
			this.formDoel.doel.amount = value;
		},
		resetForm() {
			this.$data.formDoel = {
				tracking: false,
				changed: null,
				fitness: 1,
				activity: "passief",
				goalText: "",
				doel: {
					slug: undefined,
					amount: undefined,
				} as unknown as DoelDetail,
				achieve: {
					cardio: false,
					kracht: false,
					groepslessen: false,
					afvallenInAchtWeken: false,
					sportvasten: false,
					personaltrainer: false,
					spierballenInAchtWeken: false,
				},
				helpAchieve: "",
				timeAchieve: "",
				frequency: 0,
				duration: undefined as unknown as number,
			};
		},
		async cancel() {
			await this.gebruikerStore.getDoel();
			this.editing = false;
		},
		async changeTracking(active: boolean) {
			await this.gebruikerStore.updateTracking({
				value: active,
				type: "doel",
			});

			if (active) await this.getRequiredInfo();
		},
	},
});
